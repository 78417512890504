import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6404 1.78572H20.6915L20.0689 0.742197C19.937 0.519102 19.7338 0.33144 19.4822 0.200321C19.2306 0.069203 18.9405 -0.000168971 18.6447 9.78388e-06H11.0734C10.7782 -0.000946161 10.4887 0.0681672 10.238 0.199431C9.9873 0.330696 9.78555 0.518801 9.65588 0.742197L9.03322 1.78572H1.08438C0.803293 1.78572 0.533717 1.87979 0.334958 2.04724C0.136198 2.21468 0.0245361 2.44178 0.0245361 2.67858L0.0245361 4.4643C0.0245361 4.7011 0.136198 4.9282 0.334958 5.09564C0.533717 5.26308 0.803293 5.35715 1.08438 5.35715H28.6404C28.9215 5.35715 29.191 5.26308 29.3898 5.09564C29.5886 4.9282 29.7002 4.7011 29.7002 4.4643V2.67858C29.7002 2.44178 29.5886 2.21468 29.3898 2.04724C29.191 1.87979 28.9215 1.78572 28.6404 1.78572ZM3.54852 26.0603C3.59907 26.7403 3.95535 27.3785 4.54482 27.8451C5.13429 28.3116 5.91264 28.5714 6.72144 28.5714H23.0033C23.8121 28.5714 24.5905 28.3116 25.1799 27.8451C25.7694 27.3785 26.1257 26.7403 26.1762 26.0603L27.5805 7.14287H2.14423L3.54852 26.0603Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default TrashIcon;
