import React, {useEffect, useState} from 'react';
import cls from './karta.module.scss'
import Head from "../../components/UI/page-head/head";
import $api from "../../api";
import KartaItem from "./kartaItem";
import { toast } from 'react-toastify';

const Karta = () => {

    const [del, setDel] = useState(false)
    const [result, setResult] = useState({
        data: [],
        error: true
    })
    useEffect(() => {
        $api
            .get('/api/robot/v1/card', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult({
                    data: res.data.data,
                    error: false
                })
            })
    }, [del])

    // delete card
    const deleteCard = (id) => {
        $api
            .delete(`/api/robot/v1/card/${id}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setDel(!del)
                toast.success('Ochirildi')
            })
            .catch(err => {
                if (!err?.response) {
                    toast.error('No Server Response')
                } else {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                }
            })
    }


    return (
        <div className={cls.karta}>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/menu'} title={'BANK KARTALAR'} icon={true} iconLink={'/karta/qoshish'} />
                    <div className={cls.body}>
                        <ul className={cls.body__list}>
                            {
                                result.data.length >= 1 ?
                                    result.data.map(i => (
                                        <KartaItem i={i} deleteCard={deleteCard} key={i.id}/>
                                    ))
                                    : <p className='loading red'>Kartalar yoq !</p>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Karta;
