import './Overview.scss'
import React from 'react';
import {Link} from "react-router-dom";

const Overview = () => {
    return (
        <div className='over'>
            <div className="container">
                <div className="over__inner">
                    <div className="over__head row between align-center">
                        <h2 className='over__title'>COIN-EXCHANGE</h2>
                        <div className='row' style={{gap: '10px'}}>
                            <Link className='over__link' to='/sign-in'>Kirish</Link>
                            <Link className='over__link' to='/sign-up'>Ro’yxatdan o’tish</Link>
                        </div>
                    </div>
                    <div className="over__body body">
                        <div className="body__wrapper">
                            <h3 className='body__title'>Platforma qonun qoidalari</h3>
                            <p className="body__desc">
                                1.1. Kirish <br/>
                                Mavjud Foydalanuvchi shartnomasi (keyingi o'rinlarda “Shartnoma” deb yuritiladi) coin-exchange.uz sayti tomonidan xizmat ko'rsatish tartibi va shartlarini tartibga soladi, bundan keyin “Moderator” deb yuritiladi va undan xizmat olishni istagan jismoniy shaxsga qaratilgan. ko'rsatilgan sayt (keyingi o'rinlarda "Ishtirokchi" deb yuritiladi).
                                <br/> 1.2. Xizmatdan foydalanishni boshlash uchun ishtirokchi ushbu shartnomaning barcha qoidalarini to'liq va so'zsiz qabul qiladi va agar siz ushbu shartnoma shartlaridan birortasiga rozi bo'lmasangiz, Moderator sizga xizmatdan foydalanishni rad etishingizni maslahat beradi.
                                <br/> 1.3. Muvofiqlashtiruvchi va ishtirokchi ushbu shartnomani tuzish tartibi va shaklini huquqiy jihatdan yozma shaklda tuzilgan shartnomaga ekvivalent deb qabul qiladilar.
                                <br/> 1.4. coin-exchange.uz ma'muriyati foydalanuvchilarni xabardor qilmasdan Foydalanuvchining kelishuviga va saytga har qanday o'zgartirish va qo'shimchalar kiritish huquqini o'zida saqlab qoladi.
                                <br/>
                                2.1 Ta'riflar.
                                <br/>
                                Ushbu - bu ish odamlarni qoshimcha daromad keltirishga va omadlarga quvonch keltirish maqsadida tuzilgan. Saytdagi pul miqdori usdt va uzs da hisoblanadi. Mazkur platformada ish jarayoni boshlashda sizda hisobingizda usdt bo'lishi kerak. Siz hisobingizdagi pullarni 2-shaxsga sotasiz. U vaqtda 2 chi shaxs sizga boshqa flatformadan kelgan sizning buyurtmachingiz bo'ladi. 2-shaxs sizga uzs midorida sizga to'lov qiladi siz tolovni qabul qilganingizni tasdiqlashiz kerak bo'ladi. Buyurtmani tasdiqlagan vaqtingizda sizning hisobingizdan berilgan miqdor qisqartiriladi va 2-shaxsning platforma hisobida mavjud bo'ladi.
                                <br/> 2.1.1 Kupon - foydalanuvchilarga beriladigan maxfiy sifr.
                                <br/> 2.1.2 Kupon sifrasini Moderator bergan foydanluvchidan boshqa odamlarga bermaslik.
                                <br/>
                                <br/>
                                3.1 Tomonlarning huquq va majburiyatlari.
                                <br/>
                                3.1.1. "coin-exchange.uz" platformasi faqat o'z mamlakatlari qonunlariga ko'ra huquqiy qobiliyatga ega bo'lgan odamlar ishtirok etishi mumkin. Belgilangan muddatga rioya qilmaslikning barcha oqibatlari ishtirokchiga yuklanadi.
                                <br/> 3.1.2. Platformada muddati va tartibi ishtirokchining o'zi tomonidan belgilanadi, lekin ushbu Shartnoma va platforma maydonchasi qoidalariga zid bo'lishi mumkin emas.
                                <br/> 3.1.3. Ishtirokchi quyidagilarga majburdir:
                                <br/> 3.1.3.1. Ro‘yxatdan o‘tishda o‘zi haqidagi ma’lumotlarni to‘g‘ri kiritish va Moderatorning birinchi talabiga ko‘ra shaxs to‘g‘risidagi ishonchli ma’lumotlarni taqdim etish, bu esa uni hisob egasi ekanini aniqlash imkonini beradi;
                                <br/> 3.1.3.2. o'yindan xalqaro huquq va Ishtirokchining yashash joyi - mamlakat qonunchiligiga zid bo'lgan har qanday harakatlarni amalga oshirish uchun foydalanmaslik;
                                <br/> 3.1.3.3. dasturiy ta'minotning hujjatsiz funksiyalari (xatolari) va xatolaridan foydalanmaslik va ular to'g'risida, shuningdek, ushbu xatolardan foydalangan shaxslar to'g'risida darhol Moderatorga xabar berish;
                                <br/> 3.1.3.4. Platformada ustinlikga erishish uchun tashqi dasturlardan foydalanmaslik;
                                <br/> 3.1.3.5. Hamkoringiz havolasini reklama qilish, pochta tarqatish va boshqa turdagi messejlarni olishga rozi bo'lmagan odamlarga (spam) foydalanmaslik;
                                <br/> 3.1.3.6. Boshqa ishtirokchilar va odamlarning platformaga kirishini cheklamaslik, boshqa o'yinchilarga, Moderator va uning sheriklari va xodimlariga hurmat bilan munosabatda bo'lish, Ularning ishiga hech qanday to'siq qo'ymaslik;
                                <br/> 3.1.3.7. Moderator va boshqalarni aldamaslik;
                                <br/> 3.1.3.8. Har qanday shaklda haqoratomuz so'z va haqoratlarni ishlatmaslik;
                                <br/> 3.1.3.9. Boshqa o'yinchilar va Moderatorning harakatlarini obro'sizlantirmaslik;
                                <br/> 3.1.3.10. Hech kimni zo'ravonlik bilan qo'rqitmaslik;
                                <br/> 3.1.3.11. Har qanday irq, din, millat, til, siyosat, mamlakat, mafkura yoki ijtimoiy harakatga nisbatan norozilik yoki nafratni targ‘ib qiluvchi materiallarni tarqatmaslik;
                                <br/> 3.1.3.12. Pornografiya, giyohvand moddalar yoki bunday ma'lumotlarni o'z ichiga olgan resurslarni reklama qilmaslik;
                                <br/> 3.1.3.13. ishtirokchining majburiyatlarini buzishni tasavvur qilish uchun hech qanday xatti-harakatlar, atamalar yoki jargonlardan foydalanmaslik;
                                <br/> 3.1.3.14. zarur kompyuter va boshqa turdagi xavfsizlik haqida o'z g'amxo'rlik qilish, sir saqlash va boshqa shaxsga yoki boshqa foydalanuvchilarga o'zining maxfiy ma'lumotlarini: login, akkaunt parolini bermaslik.Faqat o'zingizga tegishli bo'lgan hisobni boshqarish. Ishtirokchi o'yin maydonchasining axborot xavfsizligi tizimi ishtirokchi ma'lumotlarini notanish shaxslarga o'tkazishni istisno qilganiga rozi bo'lganligi sababli, ma'lumotlarni oshkor qilishning noqulay oqibatlarining butun xavfini o'z zimmasiga oladi.
                                <br/> 3.1.3.15. O'z operatsiyalarini hisobga olish uchun shaxsan javobgar bo'lishi. Moderator o'yinchilar o'rtasida bajarilgan harakatlar (Platforma inventarini va boshqa barcha Platforma atributlarini uzatish) uchun javobgar emas;
                                <br/> 3.1.3.16. Platforma yangiliklari va ushbu Shartnoma va o'yin maydonchasi qoidalariga kiritilgan o'zgartirishlar to'g'risida muntazam ravishda va o'zi xabardor bo'lish. Foydalanuvchi haftada bir martadan kam bo'lmagan holda Foydalanuvchining kelishuvi va funktsiyalar va xizmatlar tavsifi bilan tanishishi shart. DIQQAT (ularni o'zgartirish va to'ldirish mumkin - 1.4-bandga qarang), chunki qoidalarni bilmaslik foydalanuvchini javobgarlikdan ozod qilmaydi.
                                <br/> 3.1.3.17. ko'proq tavsiyanoma mukofotlarini olish maqsadida qo'shimcha hisoblarni yaratmaslik. Bunday harakatlar hisobni blokirovka qilishga yoki balansni nolga tenglashtirishga olib keladi.
                                <br/>
                                3.2 Moderatorning huquq va majburiyatlari.
                                <br/>
                                3.2.1. Moderator quyidagilarga majbur:
                                <br/> 3.2.1.1. Ishtirokchining platformaga to'lovsiz kirishini va platformada ishtirok etishini ta'minlash. Ishtirokchi Internetga kirish va u bilan bog'liq boshqa xarajatlarni o'zi to'laydi;
                                <br/> 3.2.1.2. Ishtirokchi hisobidagi platforma inventarini (usdt, som, kupon) hisobga olish;
                                <br/> 3.2.1.3. apparat-dasturiy kompleksni muntazam ravishda takomillashtirish, lekin Moderator Moderator dasturiy ta'minotida xatolik yo'qligiga va apparat ish parametrlaridan oshib ketmasligiga va uzluksiz ishlashiga kafolat bermaydi;
                                <br/> 3.2.1.4. pt tartibida Ishtirokchining shaxsiy ma'lumotlariga
                                nisbatan maxfiylik siyosatiga rioya qilish.
                                <br/>
                                3.2.2. Moderator quyidagi huquqlarga ega:
                                <br/> 3.2.2.1. ishtirokchiga ro'yxati, shuningdek ulardan foydalanish tartibi va shartlari ushbu shartnoma, platforma maydonchasi qoidalari va Moderatorning boshqa e'lonlari bilan aniqlangan qo'shimcha pullik xizmatlarni ko'rsatish. Shuningdek, Moderator istalgan vaqtda taklif etilayotgan pullik xizmatlar miqdorini, ularning narxini, nomini, turi va foydalanish samarasini o'zgartirishga haqli;
                                <br/> 3.2.2.2. Ishtirokchini shartnomani va platforma maydonchasi qoidalarini buzganlikda gumon qilganligi to'g'risida tergov olib borilayotganda ushbu shartnomaning amal qilishini to'xtatib turish va ishtirokchini Platformaga kirishdan mahrum qilish;
                                <br/> 3.2.2.3. Agar Moderator ishtirokchi ushbu shartnomani yoki ushbu shartnomaning 3.1-bandida belgilanganlar tartibda platforma maydonchasida belgilangan qoidalarni buzganligini aniqlasa, ishtirokchini o'yindan chetlatish;
                                <br/> 3.2.2.4. o'yin maydonchasida rekonstruksiya, ta'mirlash va profilaktika ishlarini olib borishda ishtirokchiga sezdirmasdan xizmatni qisman yoki to'liq to'xtatish;
                                <br/> 3.2.2.5. Koordinator platforma dasturiy ta'minotining noto'g'ri ishlashi uchun javobgar emas. Ishtirokchi dasturiy ta'minotdan "SHUNDAY" qoidasiga muvofiq foydalanadi. Agar Koordinator o'yin davomida erning ishida nosozlik borligini aniqlasa, dasturiy ta'minotning noto'g'ri ishlashi natijalari Moderator tomonidan bekor qilinishi yoki tuzatilishi mumkin. Ishtirokchi platforma xizmatlarini ko'rsatish sifati, miqdori, tartibi va muddatlari bo'yicha Koordinatorga murojaat qilmaslikka rozi;
                                <br/> 3.2.2.6. o'yin inventarizatsiya tezligini bir tomonlama o'zgartirish.
                                <br/>
                                <br/>
                                4.1 Kafolatlar va majburiyatlar.
                                <br/>
                                4.1.1. Moderator texnik nosozliklar yoki kutilmagan holatlar, masalan, Internet-provayderlar, axborot serverlari, bank va to‘lov tizimlarining noto‘g‘ri ishlashi yoki ishlamasligi va boshqa shaxslarning noqonuniy xatti-harakatlari kabi holatlar yuzaga kelganda platforma maydonchasi va xizmatlardan uzluksiz foydalanishni kafolatlamaydi. odamlar. Moderator hech qanday nosozliklarga yo'l qo'ymaslikka harakat qiladi, ammo nosozliklar sabablaridan qat'i nazar, Platforma vaqtinchalik texnik nosozliklar va uzilishlar uchun javobgar emas.
                                <br/> 4.2 Ishtirokchi Koordinator o'yin elektronikasi va ma'lumotlar bazasi xavfsizligi tizimini noto'g'ri ishlashga qaratilgan boshqa shaxslarning noqonuniy xatti-harakatlari yoki Moderatorga bog'liq bo'lmagan har qanday qoidabuzarliklar tufayli Ishtirokchining yo'qotishlari uchun javobgar bo'lmasligiga rozi bo'ladi, Ishtirokchi bilan hamkorlik qilish uchun foydalaniladigan kanallar va telekommunikatsiyalar ishini to'xtatish yoki to'xtatish, shuningdek, to'lov tizimlari va boshqa shaxslarning noqonuniy va noto'g'ri harakatlari tufayli.
                                <br/> 5.3.Moderator Ishtirokchi tomonidan, platforma qoidalari va platforma o'zi haqidagi ma'lumotlardan foydalanish yoki foydalanmaslik natijasida yuzaga keladigan yo'qotishlar uchun javobgar emas va Ishtirokchining malakasiz harakatlari natijasida yuzaga kelgan yo'qotishlar va zararlar uchun javobgar emas. yoki qoidalarni bilmasligi yoki buxgalteriya hisobidagi xatolari.
                                <br/> 5.4. Ishtirokchi platforma maydonchasidan o'z xohishiga ko'ra va o'z tavakkalchiligida foydalanishiga rozi bo'ladi. Moderator  Ishtirokchiga platforma foyda olishiga hech qanday kafolat bermaydi. Platformada ishlash hajmini Ishtirokchining o'zi belgilaydi.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
