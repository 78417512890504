import React, {useState} from 'react';
import cls from '../hisob.module.scss'
import Head from "../../../components/UI/page-head/head";
import HisobTasdiqlash from "./hisob-tasdiqlash";

const HisobToldirish = () => {

    const [next, setNext] = useState(false)
    const [usdt, setUsdt] = useState('')

    return (
        <div className={cls.hisob}>
            <div className="container" style={{padding: '0'}}>
                {
                    !next ?
                        <div>
                            <Head link={'/hisob'} title={'HISOBNI TO’LDIRISH'} />
                            <div className={cls.body}>
                                <div className={cls.body__inner}>
                                    <input className={cls.body__inp} type="tel" onChange={(e) => setUsdt(e.target.value)} placeholder='Usdt miqdori'/>
                                    <p className={cls.body__desc}>Hisobingizni to’ldirmoqchi bo’lgan summani usdt da kiriting misol uchun 100
                                        Ishonch hosil qilib keyingi sahifa tugmasini bosing  !</p>
                                    <button
                                        className={cls.body__btn}
                                        onClick={() => usdt.length > 0 && setNext(true) }
                                    >
                                        Keyingi sahifa
                                    </button>
                                </div>
                            </div>
                        </div>
                        : <HisobTasdiqlash usdt={usdt} />
                }
            </div>
        </div>
    );
};

export default HisobToldirish;
