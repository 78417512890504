import './Landing.scss'
import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo.svg'
import mainImg from '../../assets/images/lending-main.png'
import num1 from '../../assets/images/Numbers1.svg'
import num2 from '../../assets/images/Numbers2.svg'
import num3 from '../../assets/images/Numbers3.svg'
import l1 from '../../assets/images/li1.svg'
import l2 from '../../assets/images/li2.svg'
import l3 from '../../assets/images/li3.svg'
import p2p from '../../assets/images/p2p.png'
import payeer from '../../assets/images/payeer1.svg'

const Landing = () => {

    const [active, setActive] = React.useState(1)

    return (
        <div className='container' style={{padding: 0}}>
            <div className='landing'>
                <header className="landing__head head row between align-center">
                    <img className='head__logo' src={logo} alt="logo"/>
                    <nav className='nav'>
                        <ul className='nav__list row'>
                            <li className='nav__item'>
                                <a
                                    className={`link ${active === 1 ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => setActive(prev => prev = 1)}
                                >
                                    Home
                                </a>
                            </li>
                            <li className='nav__item'>
                                <a
                                    className={`link ${active === 2 ? 'active' : ''}`}
                                    href="#about"
                                    onClick={() => setActive(prev => prev = 2)}
                                >
                                    About Us
                                </a>
                            </li>
                            <li className='nav__item'>
                                <a
                                    className={`link ${active === 3 ? 'active' : ''}`}
                                    href="#howItWorks"
                                    onClick={() => setActive(prev => prev = 3)}
                                >
                                    How it Works
                                </a>
                            </li>
                            <li className='nav__item'>
                                <Link className='link' to='/sign-up'>Sign up</Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                <div className="landing__body body">
                    <div className="body__titles">
                        <h2 className="title">WELCOME TO USDT EXCHANGE</h2>
                        <img className='img' src={mainImg} alt="img"/>
                    </div>
                    <div id='about' className="body__start start">
                        <h3 className="start__title">How to get started</h3>
                        <ul className='start__list'>
                            <li className="start__item item row between">
                                <div className='item__titles'>
                                    <img className='img' src={num1} alt="number"/>
                                    <p className='txt'><span className="blue">Register</span> our Web site</p>
                                </div>
                                <img className='item__img' src={l1} alt="img"/>
                            </li>
                            <li className="start__item item row between">
                                <div className='item__titles'>
                                    <img className='img' src={num2} alt="number"/>
                                    <p className='txt'>You have Binance or whatever crypto market must have an account</p>
                                </div>
                                <img className='item__img' src={l2} alt="img"/>
                            </li>
                            <li className="start__item item row between">
                                <div className='item__titles'>
                                    <img className='img' src={num3} alt="number"/>
                                    <p className='txt'>Get start p2p with  50 USDT</p>
                                </div>
                                <img className='item__img' src={l3} alt="img"/>
                            </li>
                        </ul>
                        <div className="start__btns">
                            <Link className='btn' to='/sign-up'>Register</Link>
                            <Link className='btn' to='/sign-in'>Login</Link>
                        </div>
                    </div>
                    <div id='howItWorks' className="body__about about">
                        <img className='about__img' src={p2p} alt='img'/>
                        <p className='about__desc'>
                            What is p2p trading ?
                            <br/><br/>
                            P2P stands for "peer-to-peer," which is a type of decentralized network where two or more computers or devices can communicate and exchange resources directly with each other, without the need for a central server or intermediary. In a P2P network, each device is considered equal and can both send and receive data.
                            <br/><br/>
                            P2P networks are commonly associated with file sharing, such as BitTorrent, where users can download and share files with each other without the need for a centralized server. P2P networks are also used in cryptocurrency, where transactions are validated and confirmed by nodes on the network, rather than a centralized authority like a bank.
                            <br/><br/>
                            P2P networks have become increasingly popular in recent years due to their ability to provide greater privacy, security, and decentralization compared to traditional client-server networks. However, they can also be more complex to set up and may require additional technical expertise to use effectively.
                        </p>
                        <div className='about__links'>
                            <div>
                                <span className='txt'>Contact phone and email</span>
                                <a className='tel' href="tel: +16102458058">+16102458058</a>
                            </div>
                            <a className='email' href="mailto: usdexchange.contact@gmail.com">usdexchange.contact@gmail.com</a>
                        </div>
                    </div>
                </div>
                <footer className="landing__footer footer row between">
                    <div className='footer__payeer row'>
                        <img className='img' src={payeer} alt="img"/>
                    </div>
                    <div>
                        <address className='footer__address'>
                            426 Canyon Vista Dr, Los <br/> Angels, 90001
                        </address>
                        <p className='footer__desc'>© 2023 Usd exchange. all rights reserved</p>
                    </div>
                    <ul className='footer__list'>
                        <li className='item'>
                            <a className='item__link' href="#about">About us</a>
                        </li>
                        <li className='item'>
                            <a className='item__link' href="#howItWorks">Gow it works</a>
                        </li>
                        <li className='item'>
                            <Link className='item__link' to='/sign-up'>Sign-up</Link>
                        </li>
                        <li className='item'>
                            <Link className='item__link' to='/sign-in'>Sign in</Link>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
};

export default Landing;
