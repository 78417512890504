import React, {useEffect, useRef, useState} from 'react';
import cls from './sozlamalar.module.scss'
import Head from "../../components/UI/page-head/head";
import $api from "../../api";
import {Slide, toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Sozlamalar = () => {

    const navigate = useNavigate()

    const number = useRef()
    const name = useRef()
    const oldPassword = useRef()
    const password = useRef()
    const confirmPassword = useRef()

    // edit me
    const editMe = (e) => {
        e.preventDefault()

        const item = {
            phoneNumber: number.current?.value,
            name: name.current?.value,
            oldPassword: oldPassword.current?.value,
            password: password.current?.value
        }
        if (confirmPassword.current?.value === password.current?.value) {
            $api
                .put('/api/robot/v1/user/edit-me', JSON.stringify(item), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token'),
                    }
                })
                .then(() => {
                    toast.success('Ozgardi !')
                    setTimeout(() => {
                        navigate('/menu')
                    }, 1000)
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        localStorage.removeItem('token')
                        navigate('/sign-in')
                    }
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                })
        } else {
            toast.error('Parollar togri kelmayabdi !')
        }
    }

    // get me
    const [me, setMe] = useState({
        data: [],
        error: true
    })
    useEffect(() => {
        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMe({
                    data: res.data.data,
                    error: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className={cls.sozlamalar}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/menu'} title={'SOZLAMALAR'} />
                    <div className={cls.body}>
                        <form className={cls.body__inner}>
                            <label style={{display: 'block'}}>
                                <input
                                    className={cls.inp}
                                    type='tel'
                                    placeholder='TELEFON RAQAM'
                                    defaultValue={me.data.phoneNumber}
                                    ref={number}
                                />
                            </label>
                            <label style={{display: 'block'}}>
                                <input
                                    className={cls.inp}
                                    type='text'
                                    placeholder='ISM FAMILIYA'
                                    defaultValue={me.data.name}
                                    ref={name}
                                />
                            </label>
                            <label style={{display: 'block'}}>
                                <input
                                    className={cls.inp}
                                    type='password'
                                    placeholder='ESKI PAROL'
                                    ref={oldPassword}
                                />
                            </label>
                            <label style={{display: 'block'}}>
                                <input
                                    className={cls.inp}
                                    type='password'
                                    placeholder='PAROL'
                                    ref={password}
                                />
                            </label>
                            <label style={{display: 'block'}}>
                                <input
                                    className={cls.inp}
                                    type='password'
                                    placeholder='PAROLNI QAYTADAN KIRITING'
                                    ref={confirmPassword}
                                />
                            </label>
                            <button className={cls.body__btn} onClick={editMe}>TASDIQLASH</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sozlamalar;
