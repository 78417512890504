import React, {useState} from 'react';
import './signUp.scss'
import cls from "../sign.module.scss";
import MyInput from "../input/my-input";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import $api from "../../../api";
import {Slide, toast, ToastContainer} from "react-toastify";

const SignUp = () => {

    const refCode = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [referralCode, setReferralCode] = useState('')
    const [checked, setChecked] = useState(false)

    const signUp = (e) => {
        e.preventDefault()

        const item = {
            name: name,
            phoneNumber: phoneNumber,
            password: password,
            referralCode: referralCode !== '' ? referralCode : refCode ? refCode.id : referralCode,
            isOne: false
        }
        if (confirmPassword === password) {
            if (checked) {
                $api
                    .post('/api/robot/v1/auth/sign-up',
                        JSON.stringify(item), {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                    .then(res => {
                        const accessToken = res.data.data.accessToken
                        localStorage.setItem('token', `Bearer ${accessToken}`)
                        navigate(from, { replace: true });
                    })
                    .catch(err => {
                        if (!err?.response) {
                            toast.error('No Server Response')
                        } else {
                            err.response.data.errors.map(i => (
                                toast.error(i.errorMsg)
                            ))
                        }
                    })
            } else {
                toast.error('Rozilikni bildiring !')
            }
        } else {
            toast.error('Parollar togri kelmayabti !')
        }
    }

    return (
        <div className={cls.sign}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container">
                <h2 className={cls.title}>Welcome to Robot coin exchange</h2>
                <form className={cls.form}>
                    <MyInput
                        title={'Ism familiya'}
                        type={'text'}
                        value={setName}
                    />
                    <MyInput
                        title={'Telefon raqam'}
                        type={'tel'}
                        value={setPhoneNumber}
                    />
                    <MyInput
                        title={'Password'}
                        type={'password'}
                        value={setPassword}
                    />
                    <MyInput
                        title={'Confirm password'}
                        type={'password'}
                        value={setConfirmPassword}
                    />
                    {
                        refCode.id === undefined &&
                        <MyInput
                            title={'Referral kod'}
                            type={'tel'}
                            value={setReferralCode}
                        />
                    }
                    <label className='checkbox row align-center relative-p'>
                        <input className='checkbox__inp' type="checkbox" onClick={() => setChecked(!checked)}/>
                        <span className='checkmark'/>
                        <span className='checkbox__txt'>Men barcha <Link to={'/overview'}>shartlarga</Link> roziman</span>
                    </label>
                    <button className={cls.btn} onClick={signUp}>Hisob yaratish</button>
                </form>
                <p className={cls.link}>Sizda hisob bormi ? <Link to='/sign-in'>Hisobga kirish</Link></p>
            </div>
        </div>
    );
};

export default SignUp;
