import React from 'react';
import cls from "../savdo.module.scss";
import {formatCardNumbers} from "../../../assets/scripts/globalScripts";
import $api from "../../../api";
import {toast} from "react-toastify";

const ActiveTradeItem = ({ i, setStop, stop, setStartt, setEffect2 }) => {

    // stop trade
    const stopTrade = () => {
        $api
            .post(`/api/robot/v1/trade/stop/${i.id}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setStop(!stop)
                setStartt(0)
                setEffect2(false)
                toast.info('Savdo yakunlandi !')
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    return (
        <li style={{marginBottom: '2rem'}}>
            <div className='row between align-center'>
                <span className={cls.wrapper__title}>Sotilmoqda . . .</span>
                <button className={cls.wrapper__btn} onClick={stopTrade}>Tugatish</button>
            </div>
            <div className={cls.content}>
                <div className="row between">
                    <span>Umumiy miqdor :</span>
                    <span>{ i.totalAmount?.toFixed(2) }</span>
                </div>
                <div className="row between">
                    <span>Tolov karta raqami :</span>
                    <span>{ formatCardNumbers(i.cardNumber) }</span>
                </div>
                <div className="row between">
                    <span>Amaldagi buyurtma miqdori :</span>
                    <span>{ i.currentOrderQuantity ? i.currentOrderQuantity?.toFixed(2) : 0 }</span>
                </div>
                <div className="row between">
                    <span>Tugallangan buyurtma miqdori :</span>
                    <span>{ i.completedOrderQuantity ? i.completedOrderQuantity?.toFixed(2) : 0 }</span>
                </div>
            </div>
        </li>
    );
};

export default ActiveTradeItem;
