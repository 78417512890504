import React from "react";

const TrashIcon = () => {
return (
<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.6749 3.33333C15.2916 3.33333 16.5916 4.64166 16.5916 6.25C16.5916 7.825 15.3416 9.10833 13.7833 9.16666C13.7113 9.15833 13.6386 9.15833 13.5666 9.16666M15.2833 16.6667C15.8833 16.5417 16.4499 16.3 16.9166 15.9417C18.2166 14.9667 18.2166 13.3583 16.9166 12.3833C16.4583 12.0333 15.8999 11.8 15.3083 11.6667M7.63327 9.05833C7.54993 9.05 7.44993 9.05 7.35827 9.05833C6.40177 9.02585 5.49547 8.62236 4.83129 7.9333C4.16712 7.24423 3.79722 6.32371 3.79993 5.36666C3.79993 3.325 5.44993 1.66666 7.49993 1.66666C8.48013 1.64898 9.4272 2.02141 10.1328 2.70201C10.8384 3.38261 11.2448 4.31563 11.2624 5.29583C11.2801 6.27603 10.9077 7.2231 10.2271 7.92871C9.54649 8.63431 8.61346 9.04065 7.63327 9.05833ZM3.4666 12.1333C1.44993 13.4833 1.44993 15.6833 3.4666 17.025C5.75827 18.5583 9.5166 18.5583 11.8083 17.025C13.8249 15.675 13.8249 13.475 11.8083 12.1333C9.52494 10.6083 5.7666 10.6083 3.4666 12.1333Z"
    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
);
};

export default TrashIcon;