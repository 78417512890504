import React, {useEffect, useState} from 'react';
import cls from '../hisob.module.scss'
import {useNavigate} from "react-router-dom";
import {CopyIcon} from "../../../assets/icons";
import Head from "../../../components/UI/page-head/head";
import $api from "../../../api";
import {Slide, toast, ToastContainer} from "react-toastify";

const HisobTasdiqlash = ({ usdt }) => {

    const navigate = useNavigate()

    // get data
    const [result, setResult] = useState({
        data: [],
        error: true
    })
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                console.log(res)
                setResult({
                    data: res.data.data,
                    error: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const [file, setFile] = useState({
        data: {},
        error: true
    })
    const pay = () => {
        const item = {
            attachmentId: file.data.id,
            amount: usdt
        }
        if (file.error === false) {
            $api
                .post('/api/robot/v1/payment-order/pay-engine', JSON.stringify(item), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token')
                    }
                })
                .then(res => {
                    toast.success('Admin bir necha daqiqada korib chiqadi !')
                    setTimeout(() => {
                        navigate('/menu')
                    }, 2000)
                })
                .catch(err => {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                })
        } else {
            toast.error('Rasm yuklang')
        }
    }

    // send image
    const sendFile = (files) => {
        const file = files[0]
        const formData = new FormData()
        formData.append('file', file)
        $api
            .post('/api/robot/v1/attachment/upload', formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(function (res) {
                setFile({
                    data: res.data.data[0],
                    error: false
                })
            })
    }

    return (
        <div>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <Head link={'/hisob'} title={'HISOBNI TASDIQLASH'} />
            <div className={cls.body}>
                <div className={cls.body__inner}>
                    <div style={{marginBottom: '1rem'}}>
                        <div className={cls.body__inps}>
                            <input className={cls.body__inp} style={{paddingRight: '2rem'}} value={result.data.wallet} type='text'/>
                            <CopyIcon />
                        </div>
                        <p className={cls.body__desc_red}>Tolov qilayotgan usdt adresga etiborli bo’ling faqatgina TRC20  setida yubiring boshqa setda yuborilgan to’lovlar qabul qilinmaydi bekor qilinadi !</p>
                    </div>
                    <div>
                        <div className={cls.body__inps}>
                            <input className={cls.body__inp} value={usdt} type="tel" placeholder='Usdt miqdori'/>
                            <CopyIcon />
                        </div>
                        <p className={cls.body__desc}>Tolovni ko’rsatilgan miqdordan kam yubormang !</p>
                    </div>
                    <label style={{textAlign: 'start'}}>
                        <input className={cls.body__inp_file} type="file" onChange={(e) => sendFile(e.target.files)}/>
                        <span className={cls.body__file}>RASM YUKLASH</span>
                    </label>
                    <button className={cls.body__btn} onClick={pay}>Tolovni tasdiqlash</button>
                </div>
            </div>
        </div>
    );
};

export default HisobTasdiqlash;
