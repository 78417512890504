import './Hisob.scss'
import React, {useEffect, useState} from 'react';
import cls from '../hisob.module.scss'
import Head from "../../../components/UI/page-head/head";
import payeer from '../../../assets/images/payeer1.svg'
import payeer2 from '../../../assets/images/payeer2.svg'
import ButtonLoader from 'react_button_loader';
import $api from '../../../api';
import {Slide, toast, ToastContainer} from "react-toastify";
import { useNavigate } from 'react-router-dom';

const HisobToldirishPayeer = () => {
    
    const navigate = useNavigate()

    const [load, setLoad] = useState(false)
    const [usdt, setUsdt] = useState(0)

    const payment = () => {
        const item = {
            amount: usdt,
            desc: 'nmadr'
        }
        $api
            .post('/api/robot/v1/payment/payeer-order', item, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTimeout(() => {
                    setLoad(false)
                    const link = document.createElement('a')
                    link.href = res.data.message
                    link.setAttribute('target', '_blank')
                    document.body.appendChild(link)
                    link.click()
                }, 1500)
                setTimeout(() => navigate('/menu'), 1600)
            })            
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
                setTimeout(() => setLoad(false), 1500)
            })
    }

    useEffect(() => {
        if (load) {
            payment()
        }
    }, [load, payment])


    return (
        <div className={cls.hisob}>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/menu'} title={'HISOBNI TO’LDIRISH'} />
                    <div className={cls.body}>
                        <div className={cls.body__inner}>
                            <img className={cls.body__img} src={payeer} alt="img"/>
                            <input
                                className={cls.body__inp}
                                style={{marginBottom: '1rem'}}
                                type="tel"
                                onChange={(e) => setUsdt(e.target.value)}
                                placeholder='Usdt miqdori'
                            />
                            <p className={cls.body__desc}>
                                Hisobingizni to’ldirmoqchi bo’lgan summani usdt da kiriting misol uchun 100
                                <br/> Siz to’lovni payeer da ko’rsatilgan adressga 25 daqiqa ichida yuboring. Yuborayotgan adressga etiborli bo’ling har safar USDT TRC20 adress o’zgaradi
                                <br/><br/> <span style={{color: '#ff0000'}}>Ishonch hosil qilib keyingi sahifa tugmasini bosing !</span>
                            </p>
                            <ButtonLoader
                                isLoading={load}
                                onClick={() => setLoad(true)}
                            >
                                Confirm
                            </ButtonLoader>
                        </div>
                    </div>
                </div>
                <img
                    style={{width: '220px', display: "block", margin: '0 auto'}}
                    src={payeer2}
                    alt="img"
                />
            </div>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        </div>
    );
};

export default HisobToldirishPayeer;
