import React from 'react';
import cls from "../sozlamalar.module.scss";

const SozlamalarInp = ({ type, placeholder, setValue }) => {
    return (
        <label style={{display: 'block'}}>
            <input className={cls.inp} type={type} onChange={(e) => setValue(e.target.value)} placeholder={placeholder}/>
        </label>
    )
}

export default SozlamalarInp;
