import React, {useEffect, useState} from 'react';
import cls from './buyurtmalar.module.scss'
import Head from "../../../../components/UI/page-head/head";
import $api from "../../../../api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import BuyurtmalarItem from "./buyurtmalarItem";

const Buyurtmalar = () => {

    const navigate = useNavigate()

    const [activeList, setActiveList] = useState(1)

    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/trade-order/my-trade-order-history?type=${activeList === 1 ? 'REJECT' : 'PAYED'}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate, activeList])

    return (
        <div className={cls.buyurtma}>
            <div className='container' style={{padding: '0'}}>
                <div>
                    <Head link={'/savdo'} title={'Tasdiqlangan va bekor qilingan buyurtmalar'} />
                    <div className={cls.body}>
                        <div className='row evenly align-center' style={{margin: '1rem 0 2rem 0', flexFlow: 'nowrap'}}>
                            <button
                                className={cls.body__txt}
                                style={{color: activeList === 1 ? '#007074' : '#111'}}
                                onClick={() => setActiveList(1)}
                            >
                                Bekor qilingan buyurtmalar
                            </button>
                            <button className={cls.body__txt}
                                  style={{color: activeList === 2 ? '#007074' : '#111', marginBottom: '0'}}
                                  onClick={() => setActiveList(2)}
                            >
                                Tasdiqlangan buyurtmalar
                            </button>
                        </div>
                        <ul className='row' style={{flexFlow: 'column', gap: '1rem'}}>
                            {
                                result.success ?
                                    result.data.length > 0 ?
                                        result.data.map(i => (
                                            <BuyurtmalarItem i={i} activeList={activeList} key={i.id} />
                                        ))
                                        : <p className='loading red'>No data !</p>
                                    : <p className='loading'>Loading . . .</p>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Buyurtmalar;
