import React from 'react';
import cls from "./buyurtmalar.module.scss";

const BuyurtmalarItem = ({ i, activeList }) => {

    return (
        <li className={cls.item}>
            <span className={cls.item__title}>Buyurtma raqami : <span>{ i.trade.id }</span></span>
            <p style={{color: activeList === 1 ? '#FFC700' : '#24FF00'}}>
                {
                    i.status === 'PAYED' ? 'Buyurtma tasdiqlangan'
                        : i.status === 'NOT_PAYED' ? 'Bekor qilingan buyurtmalar'
                            : i.status === 'REJECT_BAT_PENDING' ? 'Admin tomonidan tekshirilmoqda'
                                : 'null'
                }
            </p>
            <div className="row between">
                <span>Sotilgan miqdor :</span>
                <span>{ i.soldUSDT ? i.soldUSDT.toFixed(2) : '{null}' } usdt</span>
            </div>
            <div className="row between">
                <span>Sotilgan miqdor (so’m) :</span>
                <span>{ i.amount } sum</span>
            </div>
            <div className="row between">
                <span>Karta raqami :</span>
                <span>{ i.cardNumber }</span>
            </div>
            <div className="row between" style={{marginBottom: '0'}}>
                <span>Yaratilgan vaqt :</span>
                <span>{ new Date(i.date).toLocaleString() }</span>
            </div>
        </li>
    );
};

export default BuyurtmalarItem;
