import React, {useState} from 'react';
import cls from '../karta.module.scss'
import Head from "../../../components/UI/page-head/head";
import SozlamalarInp from "../../sozlamalar/sozlamalar-input/sozlamalar-inp";
import $api from "../../../api";
import {Slide, toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";


const KartaQoshish = () => {

    const navigate = useNavigate()

    const [cardNumber, setCardNumber] = useState('')
    const [name, setName] = useState('')
    const [bankName, setBankName] = useState('')

    const addCard = (e) => {
        e.preventDefault()

        const item = {
            number: cardNumber,
            name: name,
            bankName: bankName
        }
        $api
            .post('/api/robot/v1/card', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Qoshildi !')
                setTimeout(() => {
                    navigate('/karta')
                }, 1000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                  toast.error(i.errorMsg)
                ))
            })
    }

    return (
        <div className={cls.karta}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/karta'} title={'BANK KARTASI'} icon={true} />
                    <div className={cls.body}>
                        <form className={cls.body__inner}>
                            <SozlamalarInp type={'number'} setValue={setCardNumber} placeholder={'KARTA RAQAMI'} />
                            <SozlamalarInp type={'text'} setValue={setName} placeholder={'ISM FAMILIYA'} />
                            <SozlamalarInp type={'text'} setValue={setBankName} placeholder={'BANK NOMI'} />
                            <button className={cls.body__btn} onClick={addCard}>TASDIQLASH</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KartaQoshish;
