import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import Layout from "../pages/layout/Layout"

const RequireAuth = () => {

    const location = useLocation()

    const values = Object.keys(localStorage);

    return (
        values.includes('token') ? <Layout />
            : <Navigate from='/' to='/lending' state={{from: location}} replace />
    )
}

export default RequireAuth;