import React, {useEffect, useState} from 'react';
import cls from './savdo.module.scss'
import {useNavigate} from "react-router-dom";
import $api from "../../api";
import {Slide, ToastContainer} from "react-toastify";
import p2p from '../../assets/images/p2p.png'

const Xarid = () => {

    const navigate = useNavigate()

    const [kurs, setKurs] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setKurs(res.data.data.exchangeRate)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    navigate('/sign-in')
                    localStorage.removeItem('token')
                }
            })
    }, [navigate])


    return (
        <div className={cls.savdo}>
            <div className="container" style={{padding: '0'}}>
                <div className={cls.inner}>
                    <div>
                        <h2 className={cls.title}>1 USDT = { kurs } so’m</h2>
                        <button className={cls.btn1} onClick={() => navigate('/savdo')}>SOTIB OLISH</button>
                    </div>
                </div>
                <div className={cls.descs}>
                    <p className={cls.descs__txt}>
                        Hozirda ushbu bo’lim ishga tushmagan usdt ni arzon narxda sotib olib p2p savdoni boshlamoqchi bo’lsangiz adminga bog’laning u sizga arzon usdt sotadi !
                    </p>
                    <img className={cls.descs__img} src={p2p} alt="img"/>
                </div>
            </div>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        </div>
    );
};

export default Xarid;
