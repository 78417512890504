import './Choose.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../../components/UI/page-head/head";
import payeer from '../../../assets/images/payeer.png'
import {useNavigate} from "react-router-dom";
import $api from "../../../api";

const Choose = ({ deposit }) => {

    const navigate = useNavigate()

    const [active, setActive] = useState(0)

    // get access
    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/payment/accesses?all=false', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
    }, [])

    return (
        <div className='container'>
            <div className="choose">
                <Head title={'Select deposit'} link={'/menu'} />
                <div className="choose__body body">
                    <div style={{height: '122px'}}>
                        {
                            result.success ?
                                <div className='body__payment'>
                                    {
                                        result.data.map(i => (
                                            i.access &&
                                            <div
                                                className={`imgs`}
                                                onClick={() => navigate(i.payType === 'PAYEER' ? 'payeer' : 'card')}
                                            >
                                                <img
                                                    className='img'
                                                    src={i.payType === 'PAYEER' ? payeer : 'https://topobmin.com/wp-content/uploads/2022/04/tether-usdt-logo.png'}
                                                    alt="payeer"
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Choose;
