import React, {useEffect, useState} from 'react';
import cls from '../savdo.module.scss'
import {DocsIcon} from "../../../assets/icons";
import {Link, useNavigate} from "react-router-dom";
import $api from "../../../api";
import {toast} from "react-toastify";
import TradesItem from "./TradesItem";
import ActiveTradeItem from "./ActiveTradeItem";

const SavdoBoshlash = ({ setStartt, kurs, effect2, setEffect2 }) => {

    const navigate = useNavigate()

    //for use effect
    const [effect, setEffect] = useState(false)
    const [stop, setStop] = useState(false)

    // get active trade
    const [activeTrade, setActiveTrade] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/trade', { 
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setActiveTrade(res.data)
                setTimeout(() => {
                    setEffect2(true)
                }, 1500)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate, stop, effect, effect2 === true])

    // get trades
    const [trades, setTrades] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/trade-order/my-trade-order-history?type=NOT_PAYED', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTrades(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate, effect])

    return (
        <div>
            <div className={cls.inner}>
                <Link className={cls.inner__docs} to='/savdo/arxiv'>
                    <DocsIcon />
                </Link>
                <div>
                    <h2 className={cls.title}>1 USDT = { kurs } so’m</h2>
                    <button className={cls.btn1}>SAVDO DAVOM ETMOQDA . . .</button>
                </div>
            </div>
                <div className={cls.wrapper}>
            {
                activeTrade.success &&
                activeTrade.data.length > 0 &&
                    <ul>
                        {
                            activeTrade.data.map(i => (
                                <ActiveTradeItem i={i} setStartt={setStartt} setStop={setStop} stop={stop} setEffect2={setEffect2} key={i.id} />
                            ))
                        }
                    </ul>
            }
                <ul>
                    {
                        trades.success &&
                        trades.data.map(i => (
                            <TradesItem i={i} effect={effect} setEffect={setEffect} key={i.id} />
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};

export default SavdoBoshlash;
