import React, {useState} from 'react';
import cls from "../savdo.module.scss";
import $api from "../../../api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const TradesItem = ({ i, effect, setEffect }) => {

    const navigate = useNavigate()

    const [modal, setModal] = useState(false)

    // send file
    const [file, setFile] = useState(null)
    const sendFile = (files) => {
        const file = files[0]
        const formData = new FormData()
        formData.append('file', file)
        $api
            .post('/api/robot/v1/attachment/upload', formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(function (res) {
                setFile(res.data.data[0])
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const confirmTrade = () => {
        const item = {
            tradeOrderId: i.id,
            reject: false
        }
        $api
            .post('/api/robot/v1/trade-order/accept-my-trade-order', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setEffect(!effect)
                toast.success('Tasdiqlandi')
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const [cancel, setCancel] = useState(false)
    const [cancelDesc, setCancelDesc] = useState('')
    const cancelTrade = () => {
        const item = {
            tradeOrderId: i.id,
            reject: true,
            comment: cancelDesc,
            attachmentId: file ? file.id : null
        }
        $api
            .post('/api/robot/v1/trade-order/accept-my-trade-order', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setCancel(false)
                setEffect(!effect)
                toast.success('Bekor qilindi')
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    return (
        <li>
            {
                modal &&
                <div className={cls.modal1}>
                    <div className={cls.modal1__content}>
                        <span className={cls.modal1__title}>Buyurtmani tasdiqlang !</span>
                        <div className={cls.modal1__conf}>
                            <button className={cls.modal1__confirm} onClick={confirmTrade}>
                                <i className="fa-solid fa-check"/>
                            </button>
                            <button className={cls.modal1__cancel} onClick={() => setModal(false)}>
                                <i className="fa-solid fa-xmark"/>
                            </button>
                        </div>
                    </div>
                    <div className={cls.modal__bg} onClick={() => setModal(false)}/>
                </div>
            }
            <p className={cls.wrapper__title}>Buyurtma bajarilmoqda . . .</p>
            <div className={cls.content}>
                <div className="row between">
                    <span>Buyurtma holati :</span>
                    <span>{ i.status === 'NOT_PAYED' && 'To\'lanmagan' }</span>
                </div>
                <div className="row between">
                    <span>Buyurtma raqami : </span>
                    <span className={cls.content__id}>{ i.id }</span>
                </div>
                <div className="row between">
                    <span>Usdt miqdori :</span>
                    <span>{ i.soldUSDT.toFixed(2) }</span>
                </div>
                <div className="row between">
                    <span>Sotildi (so’m) :</span>
                    <span>{ i.amount }</span>
                </div>
                <div className="row between">
                    <span>Xaridorning ismi :</span>
                    <span>{ i.user.name }</span>
                </div>
                <div className="row between">
                    <span>Telefon raqami :</span>
                    <span>{ i.phoneNumber }</span>
                </div>
                <div className="row between">
                    <span>Karta raqami :</span>
                    <span>{ i.cardNumber }</span>
                </div>
                <div style={{textAlign: 'end'}}>
                    <button
                        className={cls.content__btn_red}
                        onClick={() => setCancel(true)}
                    >
                        Buyurtmani bekor qilish
                    </button>
                    <button
                        className={cls.content__btn}
                        onClick={() => setModal(true)}
                    >
                        Buyurtmani tasdiqlash
                    </button>
                </div>
            </div>
            <>
                {
                    cancel &&
                    <div className={cls.modal}>
                        <div className="container" style={{padding: '0'}}>
                            <div className={cls.modal__content}>
                                <button className={cls.modal__close} onClick={() => setCancel(false)}>
                                    <i className="fa-solid fa-xmark" />
                                </button>
                                <textarea
                                    className={cls.modal__inp}
                                    placeholder='Bekor qilish sababini kiriting...'
                                    onChange={(e) => setCancelDesc(e.target.value)}
                                />
                                <div className="row between">
                                    <label>
                                        <input className={cls.modal__file} onChange={(e) => sendFile(e.target.files)} type="file"/>
                                        <span
                                            className={cls.modal__btn}
                                            style={{background: '#fff', color: '#111'}}
                                        >
                                            { file ? file.name : 'Rasm yuklash' }
                                        </span>
                                    </label>
                                    <button
                                        className={cls.modal__btn}
                                        onClick={cancelTrade}
                                    >
                                        Bekor qilish
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        </li>
    );
};

export default TradesItem;
