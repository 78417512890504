import './App.scss';

// global styles
import './assets/styles/normalize.css'
import './assets/styles/flex-box.css'
import './assets/styles/global.css'

import {BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useLayoutEffect, useState} from "react";
import Header from "./components/header/Header";
import Home from "./pages/home-page/home";
import SignIn from "./pages/sign/sign-in/sign-in";
import SignUp from "./pages/sign/sign-up/sign-up";
import Savdo from "./pages/savdo/savdo";
import SavdoBoshlash from "./pages/savdo/boshlash/savdo-boshlash";
import Buyurtmalar from "./pages/savdo/boshlash/buyurtmalar/buyurtmalar";
import Menu from "./pages/menu/menu";
import HisobToldirishPayeer from "./pages/hisob/toldirish/hisob-toldirishPayeer";
import HisobTasdiqlash from "./pages/hisob/toldirish/hisob-tasdiqlash";
import HisobChiqarish from "./pages/hisob/chiqarish/hisob-chiqarish";
import Sozlamalar from "./pages/sozlamalar/sozlamalar";
import Karta from "./pages/karta/karta";
import KartaQoshish from "./pages/karta/qoshish/karta-qoshish";
import MyTeam from "./pages/my-team/my-team";
import RequireAuth from "./components/RequireAuth";
import PageNotFound from "./components/PageNotFound";
import Layout from "./pages/layout/Layout";
import Overview from "./pages/overview/Overview";
import $api from "./api";
import Landing from "./pages/landing-page/Landing";
import Xarid from "./pages/savdo/Xarid";
import PSuccess from "./pages/payeer/PSuccess";
import PFail from "./pages/payeer/PFail";
import Choose from "./pages/hisob/choose/Choose";
import HisobToldirish from "./pages/hisob/toldirish/HisobToldirish";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {

  // global veriables
  const [start, setStart] = useState(0)

  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Header />
          <Routes>
            <Route path='/' element={<Layout />}>

              <Route path='/lending' element={<Landing />} />

              <Route path='/overview' element={<Overview />} />

              <Route path='/sign-in' element={<SignIn />} />
              <Route path='/sign-up/' element={<SignUp />} />
              <Route path='/sign-up/:id' element={<SignUp />} />

              <Route path='/success/:id/:ps/:date/:payDate/:shop/:orderId/:amount/:currency/:desc/:status' element={<PSuccess />} />
              <Route path='/success' element={<PSuccess />} />
              <Route path='/fail' element={<PFail />} />

              <Route element={<RequireAuth />}>
                <Route path='/' element={<Home />} />
                <Route path='/savdo' element={<Savdo start={start} setStart={setStart} />} />
                <Route path='/savdo/arxiv' element={<Buyurtmalar />} />
                <Route path='/xarid' element={<Xarid />} />
                <Route path='/menu' element={<Menu />} />
                <Route path='/hisob' element={<Choose deposit={true} />} />
                <Route path='/hisob/card' element={<HisobToldirish />} />
                <Route path='/hisob/payeer' element={<HisobToldirishPayeer />} />
                <Route path='/hisob/chiqarish' element={<HisobChiqarish />} />
                <Route path='/sozlamalar' element={<Sozlamalar />} />
                <Route path='/karta' element={<Karta />} />
                <Route path='/karta/qoshish' element={<KartaQoshish />} />
                <Route path='/my-team' element={<MyTeam />} />
              </Route>

              {/* 404 */}
              <Route path='*' element={<PageNotFound />} />

            </Route>
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
