import './Payeer.scss'
import React from 'react';
import {useNavigate} from "react-router-dom";
import fail from '../../assets/images/reject.svg'

const PFail = () => {

    const navigate = useNavigate()

    React.useEffect(() => {
        setTimeout(() => navigate('/menu') , 3000)
    }, [navigate])

    const [params, setParams] = React.useState({})
    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObj = {};

        for (let [key, value] of searchParams) {
            paramsObj[key] = value;
        }

        setParams(paramsObj);
    }, [])


    return (
        <div className='container'>
            <div className="payeer page">
                <h2 className="payeer__title">Payeer fail</h2>
                <div>
                    <img className='payeer__img' src={fail} alt="success"/>
                    <div className='payeer__orders row between align-center'>
                        <span className='order'>Order ID:</span>
                        <span className='txt'>{ params.m_orderid }</span>
                    </div>
                    <div className='payeer__orders row between align-center'>
                        <span className='order'>Amount:</span>
                        <span className='txt'>{ params.m_amount } $</span>
                    </div>
                    <div className='payeer__orders row between align-center'>
                        <span className='order'>Currency:</span>
                        <span className='txt'>{ params.m_curr }</span>
                    </div>
                    <div className='payeer__orders row between align-center'>
                        <span className='order'>Description:</span>
                        <span className='txt'>{ params.m_desc }</span>
                    </div>
                    <div className='payeer__orders row between align-center'>
                        <span className='order'>Language:</span>
                        <span className='txt'>{ params.lang }</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PFail;
