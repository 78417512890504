import React, {useEffect, useState} from 'react';
import cls from './menu.module.scss'
import {
    BankIcon,
    BigUserIcon, BonusIcon, LogoutIcon,
    SettingsIcon,
    Users2Icon,
    Wallet1Icon,
    Wallet2Icon,
    WalletPlusIcon
} from "../../assets/icons";
import {Link, useNavigate} from "react-router-dom";
import $api from "../../api";
import {Slide, toast, ToastContainer} from "react-toastify";

const Menu = () => {

    const navList = [
        {
            title: 'Sozlamalar',
            href: '/sozlamalar',
            icon: <SettingsIcon />
        },
        {
            title: 'Bonus kupon',
            href: false,
            icon: <BonusIcon />
        },
        {
            title: 'Savdo qilish',
            href: '/savdo',
            icon: <BankIcon />
        },
        {
            title: 'Bank kartasi',
            href: '/karta',
            icon: <WalletPlusIcon />
        },
        {
            title: 'Mening jamoam',
            href: '/my-team',
            icon: <Users2Icon />
        },
    ]

    const navigate = useNavigate()

    const [modal, setModal] = useState(false)

    // get me
    const [me, setMe] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMe(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
            })
    }, [modal])

    // get kurs
    const [kurs, setKurs] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setKurs(res.data.data.exchangeRate)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate])

    const [cupon, setCupon] = useState('')
    const useCupon = () => {
        $api
            .post('/api/robot/v1/coupon/use', null, {
                params: {
                    "code": cupon
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setModal(false)
                toast.success('Qoshildi !')
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    // log out func
    const logOut = () => {
        localStorage.removeItem('token')
        navigate('/sign-in')
    }

    return (
        <div className={cls.menu}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            {
                modal &&
                <div className={cls.modal}>
                    <div className={cls.content}>
                        <button className={cls.content__close} onClick={() => setModal(false)}>
                            <i className="fa-solid fa-xmark"/>
                        </button>
                        <span className={cls.content__title}>KUPON KODINI KIRITING</span>
                        <input
                            className={cls.content__inp}
                            type="text"
                            onChange={(e) => setCupon(e.target.value)}
                            placeholder='KUPON KODINI KIRITING'
                        />
                        <button className={cls.content__btn} onClick={useCupon}>QABUL QILISH</button>
                    </div>
                    <div className={cls.modal__bg} onClick={() => setModal(false)}/>
                </div>
            }
            <div className="container" style={{padding: '0'}}>
                <div>
                    <div className={cls.head}>
                        <div className='row align-center'>
                            <BigUserIcon />
                            <div style={{marginLeft: '1rem'}}>
                                <span className={cls.head__txt} style={{marginBottom: '10px'}}>{ me.success && me.data.name }</span>
                                <span className={cls.head__txt}>{ me.success && me.data.phoneNumber }</span>
                            </div>
                        </div>
                        <p className={cls.head__usd}>
                            { me.success && me.data.balanceUSDT.toFixed(2) } usdt = { me.success && (me.data.balanceUSDT * kurs).toFixed(2) } so’m
                        </p>
                        <div className='row evenly' style={{marginBottom: '0'}}>
                            <Link className={cls.head__link} to='/hisob'>
                                <Wallet1Icon />
                                <span>Hisobni to’ldirish</span>
                            </Link>
                            <Link className={cls.head__link} style={{marginBottom: '0'}} to='/hisob/chiqarish'>
                                <Wallet2Icon />
                                <span>Hisobni chiqarish</span>
                            </Link>
                        </div>
                    </div>
                    <div className={cls.body}>
                        <div className={cls.body__wrapper}>
                            <ul className={cls.body__list}>
                                {
                                    navList.map((item, i) => (
                                        <li key={i}>
                                            <Link className={cls.body__link} onClick={(e) => e.target.innerText === 'Bonus kupon' && setModal(true)} to={item.href}>
                                                <div className='row align-center'>
                                                    { item.icon }
                                                    <span>{ item.title }</span>
                                                </div>
                                                <i className="fa-solid fa-chevron-right"/>
                                            </Link>
                                        </li>
                                    ))
                                }
                                <li className={cls.body__link} onClick={logOut} style={{cursor: "pointer"}}>
                                    <div className='row align-center'>
                                        <LogoutIcon />
                                        <span>Hisobdan chiqish</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-right"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
