import React from 'react';

const BonusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6418 8.33333H3.3085V15C3.3085 17.5 4.14183 18.3333 6.64183 18.3333H13.3085C15.8085 18.3333 16.6418 17.5 16.6418 15V8.33333ZM17.9168 5.83333V6.66667C17.9168 7.58333 17.4752 8.33333 16.2502 8.33333H3.75016C2.47516 8.33333 2.0835 7.58333 2.0835 6.66667V5.83333C2.0835 4.91667 2.47516 4.16667 3.75016 4.16667H16.2502C17.4752 4.16667 17.9168 4.91667 17.9168 5.83333ZM9.70016 4.16667H5.10016C4.96314 4.01805 4.88916 3.82204 4.89383 3.61995C4.89849 3.41786 4.98143 3.22547 5.12516 3.08333L6.3085 1.9C6.45826 1.75191 6.66038 1.66885 6.871 1.66885C7.08161 1.66885 7.28373 1.75191 7.4335 1.9L9.70016 4.16667ZM14.8918 4.16667H10.2918L12.5585 1.9C12.7083 1.75191 12.9104 1.66885 13.121 1.66885C13.3316 1.66885 13.5337 1.75191 13.6835 1.9L14.8668 3.08333C15.1668 3.38333 15.1752 3.85833 14.8918 4.16667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.4502 8.33334V12.6167C7.4502 13.2833 8.18353 13.675 8.74186 13.3167L9.5252 12.8C9.66126 12.7104 9.82061 12.6626 9.98353 12.6626C10.1464 12.6626 10.3058 12.7104 10.4419 12.8L11.1835 13.3C11.3087 13.3834 11.4541 13.4313 11.6044 13.4386C11.7546 13.4458 11.904 13.4121 12.0366 13.3411C12.1692 13.2701 12.28 13.1645 12.3572 13.0354C12.4345 12.9064 12.4753 12.7588 12.4752 12.6083V8.33334H7.4502Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default BonusIcon;
