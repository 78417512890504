import React from 'react';
import cls from './home.scss'
import {TgIcon} from "../../assets/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import slide1 from '../../assets/images/slide1.jpg'
import slide2 from '../../assets/images/slide2.jpg'
import slide3 from '../../assets/images/slide3.jpg'
import slide4 from '../../assets/images/slide4.png'

const Home = () => {
    return (
        <div className='home'>
            <div className="container">
                <div>
                    <a className='link' href="https://t.me/USDT_EXCHANGE_UZ" target="_blank">
                        <span>Usdt-exchange</span>
                        <TgIcon />
                    </a>
                    <div className='slider'>
                        <Swiper
                            rewind={true}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={slide1} alt="img"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={slide3} alt="img"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={slide2} alt="img"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={slide4} alt="img"/>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div>
                        <p className='desc'>Dunyodagi birinchi Stablecoin Tether tokeni</p>
                        <p className={cls.desc}>Teter tokenlari raqamli tokenlar sohasida kontseptsiyani yaratgan eng keng tarqalgan stabilkoinlardir. An'anaviy moliyaviy tizimni buzgan va an'anaviy valyutalardan raqamli foydalanishga kashshof bo'lgan Tether Tokens blokcheyn sohasida o'sib borayotgan biznes va innovatsiyalarni qo'llab-quvvatlaydi va kuchaytiradi. Tether tokenlari bir nechta blokcheynlarga asoslangan raqamli tokenlar sifatida mavjud.
                            Teter tokenlari birinchi, eng ko'p ishlatiladigan stabilkoin va hajmi bo'yicha eng ko'p sotiladigan tokenlardan biri bo'lish uchun uzoq yo'lni bosib o'tdi. Teter tokenlari bugungi kunda yirik birjalar, birjadan tashqari jadvallar va hamyonlarda eng ko'p qabul qilingan stabilkoinlardir.
                        </p>
                        <p className='desc'>Peer-to-peer (P2P) xizmati - bu markazlashtirilmagan platforma bo'lib, unda ikki kishi uchinchi tomon vositachisisiz bir-biri bilan to'g'ridan-to'g'ri muloqot qiladi. Buning o'rniga, xaridor va sotuvchi P2P xizmati orqali bir-biri bilan to'g'ridan-to'g'ri bitim tuzadilar. P2P platformasi qidiruv, skrining, reyting, to'lovni qayta ishlash yoki eskrow kabi xizmatlarni taqdim etishi mumkin.</p>
                        <p className='desc'>Peer-to-peer xizmati uchinchi tomon vositachisisiz bitim taraflarini bevosita bog'laydigan platformadir.
                            Peer-to-peer xizmatlari ishonchli uchinchi tomonlar yordamida an'anaviy tarzda hal qilinadigan ishonch, majburiyat va axborot nosimmetrikligi bilan bog'liq tranzaksiya xarajatlarini bartaraf etish uchun texnologiyadan foydalanadi.
                            Peer-to-peer platformalari o'z foydalanuvchilariga to'lovlarni qayta ishlash, xaridor va sotuvchi haqida ma'lumot va sifat kafolati kabi xizmatlarni taklif qiladi.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
