import React from 'react';

const PageNotFound = () => {
    return (
        <div className='container'>
            <p className='loading'>Page not found</p>
        </div>
    );
};

export default PageNotFound;
