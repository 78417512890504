import React from "react";

const TrashIcon = () => {
return (
<svg width="25" height="25" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.75 5.3125V0H0.9375C0.417969 0 0 0.417969 0 0.9375V19.0625C0 19.582 0.417969 20 0.9375 20H14.0625C14.582 20 15 19.582 15 19.0625V6.25H9.6875C9.17188 6.25 8.75 5.82812 8.75 5.3125ZM11.25 14.5312C11.25 14.7891 11.0391 15 10.7812 15H4.21875C3.96094 15 3.75 14.7891 3.75 14.5312V14.2188C3.75 13.9609 3.96094 13.75 4.21875 13.75H10.7812C11.0391 13.75 11.25 13.9609 11.25 14.2188V14.5312ZM11.25 12.0312C11.25 12.2891 11.0391 12.5 10.7812 12.5H4.21875C3.96094 12.5 3.75 12.2891 3.75 12.0312V11.7188C3.75 11.4609 3.96094 11.25 4.21875 11.25H10.7812C11.0391 11.25 11.25 11.4609 11.25 11.7188V12.0312ZM11.25 9.21875V9.53125C11.25 9.78906 11.0391 10 10.7812 10H4.21875C3.96094 10 3.75 9.78906 3.75 9.53125V9.21875C3.75 8.96094 3.96094 8.75 4.21875 8.75H10.7812C11.0391 8.75 11.25 8.96094 11.25 9.21875ZM15 4.76172V5H10V0H10.2383C10.4883 0 10.7266 0.0976562 10.9023 0.273438L14.7266 4.10156C14.9023 4.27734 15 4.51562 15 4.76172Z"
    fill="white" />
</svg>
);
};

export default TrashIcon;