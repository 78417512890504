import React, {useEffect, useState} from 'react';
import cls from './savdo.module.scss'
import {useNavigate, Link} from "react-router-dom";
import {DocsIcon} from "../../assets/icons";
import $api from "../../api";
import {formatCardNumbers} from "../../assets/scripts/globalScripts";
import SavdoBoshlash from "./boshlash/savdo-boshlash";
import {Slide, toast, ToastContainer} from "react-toastify";

const Savdo = ({ start, setStart }) => {

    const navigate = useNavigate()

    const [effect, setEffect] = useState(false)
    const [money, setMoney] = useState(0)
    const [usdt, setUsdt] = useState(0)
    const [kurs, setKurs] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setUsdt(res.data.data.balanceUSDT)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    navigate('/sign-in')
                    localStorage.removeItem('token')
                }
            })

        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setKurs(res.data.data.exchangeRate)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    navigate('/sign-in')
                    localStorage.removeItem('token')
                }
            })
    }, [navigate])

    // get active trade
    useEffect(() => {
        $api
            .get('/api/robot/v1/trade', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                if (res.data.data.length > 0) {
                    setStart(3)
                }
            })
    }, [navigate])


    const [cards, setCards] = useState([])
    const [cardId, setCardId] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/card', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setCards(res.data.data)
                setCardId(res.data.data[0]?.id)
            })
    }, [])

    // start trade
    const startTrade = () => {
        const item = {
            amount: money,
            cardId: cardId
        }
        $api
            .post('/api/robot/v1/trade/start', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setStart(!start)
                setMoney(0)
                toast.info('Savdo boshlandi !')
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setStart(1)
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const savdoBoshlash = () => {
        if (usdt > 0) {
            if (money >= 50) {
                if (money > 0 && money !== '' && money <= usdt && cardId > 0) {
                    startTrade()
                    setStart(2)
                } else if (money > usdt) {
                    toast.error('Hisobingizda mablag yetarli emas !')
                } else if (cardId === 0) {
                    toast.error('Kartangiz yoq, karta sahifasiga otasiz !')
                    setTimeout(() => {
                        navigate('/karta')
                    }, 2000)
                } else {
                    toast.error('USDT miqdorini kiriting !')
                }
            } else {
                toast.error('Minimal 50 usdt !')
            }
        } else {
            toast.error('Hisobingizda 0 USDT !')
        }
    }

    //get select value
    const getSelectValue = (e) => {
        setCardId(e)
    }

    return (
        <div className={cls.savdo}>
            <div className="container" style={{padding: '0'}}>
                    {
                        start === 0 ?
                        <div className={cls.inner}>
                            <Link className={cls.link} to='/savdo/arxiv'>
                                <DocsIcon />
                            </Link>
                            <div>
                                <h2 className={cls.title}>1 USDT = { kurs } so’m</h2>
                                <button className={cls.btn1} onClick={() => setStart(1)}>SAVDONI BOSHLASH</button>
                            </div>
                        </div>
                            : start === 1 ?
                            <div className={cls.inner}>
                                <div className={cls.wrapper}>
                                    <button className={cls.wrapper__navigate} onClick={() => setStart(0)}>
                                        <i className="fa-solid fa-chevron-left"/>
                                    </button>
                                    <div>
                                        <div>
                                            <input
                                                className={cls.wrapper__inp}
                                                type="tel"
                                                placeholder='Usdt miqdori'
                                                onChange={(e) => setMoney(e.target.value)}
                                            />
                                            <span className={cls.wrapper__txt}>Mavjud: { usdt.toFixed(2) } USDT</span>
                                        </div>
                                        <div className={cls.wrapper__sel}>
                                            <i className="fa-solid fa-play"/>
                                            <select
                                                className={cls.wrapper__select}
                                                value={cardId}
                                                onChange={(e) => getSelectValue(e.target.value)}
                                            >
                                                {
                                                    cards.length > 0 ?
                                                        cards.map(i => (
                                                            <option
                                                                value={i.id}
                                                                key={i.id}
                                                            >
                                                                { formatCardNumbers(i.number) + ' ' + i.bankName }
                                                            </option>
                                                        ))
                                                        : <option className='loading red'>No cards !</option>
                                                }
                                            </select>
                                        </div>
                                        <button
                                            className={cls.wrapper__link}
                                            onClick={savdoBoshlash}
                                        >
                                            SAVDONI BOSHLASH
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : <SavdoBoshlash starttTrade={startTrade} setStartt={setStart} kurs={kurs} effect2={effect} setEffect2={setEffect} />
                    }
            </div>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        </div>
    );
};

export default Savdo;
