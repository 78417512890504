import './Header.scss'
import React, {useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {BankIcon, HomeIcon, UserIcon} from "../../assets/icons";

const Header = () => {

    const location = useLocation()

    const nav = [
        {
            name: 'home',
            path: '/',
            icon: <HomeIcon />,
            txt: 'Bosh sahifa'
        },
        {
            name: 'xarid',
            path: '/xarid',
            icon: <BankIcon />,
            txt: 'Xarid qilish'
        },
        {
            name: 'trade',
            path: '/savdo',
            icon: <BankIcon />,
            txt: 'Savdo qilish'
        },
        {
            name: 'menu',
            path: '/menu',
            icon: <UserIcon />,
            txt: 'Menyu'
        },
    ]


    return (
        <div
            className='header'
            style={{display: location.pathname === '/sign-in' || location.pathname.includes('/sign-up') || location.pathname === '/lending' || location.pathname === '/overview' ? 'none' : 'block'}}
        >
            <div className="container" style={{padding: '0'}}>
                <nav>
                    <ul className='header__list'>
                        {
                            nav.map((item, i) => (
                                <li key={i}>
                                    <NavLink
                                        className='header__link'
                                        to={item.path}
                                    >
                                        {item.icon}
                                        <span>{ item.txt }</span>
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Header;
