import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

const Layout = () => {

    return (
        <Outlet />
    );
};

export default Layout;
