import React, {useEffect, useState} from 'react';
import cls from '../hisob.module.scss'
import Head from "../../../components/UI/page-head/head";
import $api from "../../../api";
import {Slide, toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

const HisobChiqarish = () => {

    const navigate = useNavigate()

    const [result, setResult] = useState({
        data: {},
        error: true
    })
    useEffect(() => {
        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult({
                    data: res.data.data,
                    error: false
                })
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate])
    console.log(result)

    const [address, setAddress] = useState('')
    const [amount, setAmount] = useState('')
    const getMoney = () => {
        const item = {
            address: address,
            amount: amount
        }
        $api
            .post('/api/robot/v1/payment-order/withdraw-p2', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Adminlar bir necha daqiqada korib chiqishadi !')
                setTimeout(() => {
                    navigate('/menu')
                }, 2000)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    return (
        <div className={cls.hisob}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/menu'} title={'HISOBNI CHIQARISH'} />
                    <div className={cls.body}>
                        <div className={cls.body__inner} style={{borderRadius: '20px'}}>
                            <div style={{marginBottom: '1rem'}}>
                                <input
                                    className={cls.body__inp}
                                    type="text"
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder='USDT ADRES NI KIRITING'
                                />
                                <p className={cls.body__desc_red}>Adressni kiritishda etiborli bo’ling !</p>
                            </div>
                            <div style={{marginBottom: '2rem'}}>
                                <input
                                    className={cls.body__inp}
                                    type="tel"
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder='SUMMANI USDT DA KIRITING'
                                />
                                <p className={cls.body__desc} style={{textAlign: 'end'}}>Hisobingizda mavjud : { result.error === false && result.data.balanceUSDT.toFixed(2) } usdt</p>
                            </div>
                            <button className={cls.body__btn_green} onClick={getMoney}>CHIQARISH</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HisobChiqarish;
