import React, {useEffect, useState} from 'react';
import cls from './my-team.module.scss'
import Head from "../../components/UI/page-head/head";
import {CopyIcon} from "../../assets/icons";
import $api, {API_URL} from "../../api";
import {Slide, toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {REFERRAL_BASE_PATH} from "../../assets/scripts/globalScripts";

const MyTeam = () => {

    const navigate = useNavigate()

    const [result, setResult] = useState({
        data: [],
        error: true
    })
    const [referralCode, setReferralCode] = useState([])
    const [team, setTeam] = useState([])
    const [profit, setProfit] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult({
                    data: res.data.data,
                    error: false
                })
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })

        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setReferralCode(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })

        $api
            .get('/api/robot/v1/referral/my-referral', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTeam(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })

        $api
            .get('/api/robot/v1/referral/my-referral/profit', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setProfit(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate])

    // copy
    const [copy1, setCopy1] = useState(false);
    const [copy2, setCopy2] = useState(false);
    const copyToClipBoard = async (e, id) => {
        await navigator.clipboard.writeText(e);
        if (id === 1) {
            setCopy1(true)
            setTimeout(() => setCopy1(false), 2000)
        } else {
            setCopy2(true)
            setTimeout(() => setCopy2(false), 2000)
        }
    };

    return (
        <div className={cls.team}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container" style={{padding: '0'}}>
                <div>
                    <Head link={'/menu'} title={'MENING JAMOAM'} />
                    <div className={cls.body}>
                        <div className={cls.inner}>
                            <div className={cls.form}>
                                <label className={cls.form__label}>
                                    <input className={cls.form__inp} value={API_URL + `/sign-up/${referralCode.success ? referralCode.data.referralCode : '0'}`} type="text" placeholder='Referal ssilka'/>
                                    <button className={cls.form__icon} onClick={() => copyToClipBoard(API_URL + `/sign-up/${referralCode.success ? referralCode.data.referralCode : '0'}`, 1)}>
                                        {
                                            copy1 ?
                                            <i className="fa-solid fa-check" style={{fontSize: '20px'}}/>
                                                : <CopyIcon />
                                        }
                                    </button>
                                </label>
                                <label className={cls.form__label2}>
                                    <input className={cls.form__inp2} type="text" value={referralCode.success ? referralCode.data.referralCode : '{null}'} placeholder='Referal code'/>
                                    <button className={cls.form__icon} onClick={() => copyToClipBoard(referralCode.success ? referralCode.data.referralCode : '{null}', 2)}>
                                        {
                                            copy2 ?
                                                <i className="fa-solid fa-check" style={{fontSize: '20px'}}/>
                                                : <CopyIcon />
                                        }
                                    </button>
                                </label>
                                <p className={cls.form__desc}>Har bitta chaqirilgan do’stingiz hisobini to’ldirsa sizga { result.data.referralPercentage2 }% miqdorida bonus beriladi </p>
                                <p className={cls.form__total}>Umumiy foyda : { profit.success ? profit.data : 0 } usdt</p>
                            </div>
                        </div>
                        <div className={cls.wrapper}>
                            <table className={cls.table}>
                                <tr>
                                    <th>No1</th>
                                    <th>Ism familiya</th>
                                    <th>Foyda</th>
                                    <th>Vaqt</th>
                                </tr>
                                {
                                    team.success ?
                                        team.data?.length > 0 ?
                                            team.data.map((i, num) => (
                                                <tr key={num+1}>
                                                    <td>{ num+1 }</td>
                                                    <td>{ i.user.name }</td>
                                                    <td>{ i.profit }</td>
                                                    <td>{ new Date(i.user.createdAt).toLocaleDateString() }</td>
                                                </tr>
                                            ))
                                            : <p className='red'>No data !</p>
                                        : <p className='loading'>Loading . . .</p>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyTeam;