import React, {useState} from 'react';
import cls from "../sign.module.scss";
import MyInput from "../input/my-input";
import {Link, useLocation, useNavigate} from "react-router-dom";
import $api from "../../../api";
import {ToastContainer, toast, Slide} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const SignIn = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')

    const signIn = (e) => {
        e.preventDefault()

        const item = {
            phoneNumber: phoneNumber,
            password: password,
        }
        $api
            .post('/api/robot/v1/auth/sign-in/trader',
                JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json"
                }
        })
            .then(res => {
                console.log(res)
                const accessToken = res.data.data.accessToken
                localStorage.setItem('token', `Bearer ${accessToken}`)
                navigate(from, { replace: true });
            })
            .catch(err => {
                if (!err?.response) {
                    toast.error('No Server Response')
                } else {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                }
            })
    }

    return (
        <div className={cls.sign}>
            <>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
            <div className="container">
                <div>
                    <h2 className={cls.title}>Login to Robot coin exchange</h2>
                    <form className={cls.form}>
                        <MyInput
                            title={'Telefon raqam'}
                            type={'tel'}
                            value={setPhoneNumber}
                        />
                        <MyInput
                            title={'Password'}
                            type={'password'}
                            value={setPassword}
                        />
                        <button className={cls.btn} onClick={signIn}>Hisobga kirish</button>
                    </form>
                    <p className={cls.link}>Sizda hisob bormi ? <Link to='/sign-up'>Hisob yaratish</Link></p>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
