import React, {useState} from 'react';
import cls from "./karta.module.scss";
import {formatCardNumbers} from "../../assets/scripts/globalScripts";
import {TrashIcon} from "../../assets/icons";

const KartaItem = ({ i, deleteCard }) => {

    const [modal, setModal] = useState(false)

    return (
        <li className={cls.item} key={i.id}>
            {
                modal &&
                <div className={cls.modal}>
                    <div className={cls.modal__content}>
                        <span className={cls.modal__title}>Tasdiqlash</span>
                        <div className={cls.item__conf}>
                            <button className={cls.item__confirm} onClick={() => deleteCard(i.id)}>
                                <i className="fa-solid fa-check"/>
                            </button>
                            <button className={cls.item__cancel} onClick={() => setModal(false)}>
                                <i className="fa-solid fa-xmark"/>
                            </button>
                        </div>
                    </div>
                    <div className={cls.modal__bg} onClick={() => setModal(false)}/>
                </div>
            }
            <div className={cls.item__titles}>
                <p className={cls.item__card}>{ formatCardNumbers(i.number) }</p>
                <span className={cls.item__name}>{ i.name }</span>
                <span className={cls.item__type}>{ i.bankName }</span>
            </div>
            <div>
                <button className={cls.item__btn} onClick={() => setModal(true)}>
                    <TrashIcon />
                </button>
            </div>
        </li>
    );
};

export default KartaItem;
