import React, {useState} from 'react';
import cls from "../sign.module.scss";
import {HidePassword} from "../../../assets/icons";

const MyInput = ({ title, type, value }) => {

    const [show, setShow] = useState(false)

    return (
        <label className='relative-p'>
            <span className={cls.label}>{ title }</span>
            <input
                className={cls.inp}
                type={show ? 'text' : type}
                onChange={(e) => value(e.target.value)}
            />
            {
                type === 'password' &&
                <button
                    className={cls.icon}
                    type="button"
                    onClick={() => setShow(!show)}
                >
                    <HidePassword />
                </button>
            }
        </label>
    );
};

export default MyInput;
